.question-radio-group label:first-child.ant-radio-button-wrapper-checked {
  outline: none;
  background-color: @success-color !important;
  border-color: @success-color !important;
}

.question-radio-group
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):first-child:hover {
  color: @success-color !important;
}

.question-radio-group label:last-child.ant-radio-button-wrapper-checked {
  outline: none;
  background-color: @error-color !important;
  border-color: @error-color !important;
}

.question-radio-group
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):last-child:hover {
  color: @error-color !important;
}

.question-error-icon {
  position: relative;
  top: -26px;
  left: -48px;
  font-size: 1.2rem;
}

@media screen {
  @media (min-width: @screen-xs) {
    .question-error-icon {
      left: -84px;
    }
  }
  @media (min-width: @screen-sm) {
    .question-error-icon {
      left: 24px;
    }
  }
  @media (min-width: @screen-md) {
    .question-error-icon {
      left: 24px;
    }
  }
  @media (min-width: @screen-lg) {
    .question-error-icon {
      left: 0px;
    }
  }
}
