.basic-policy-modal {
  top: 0;
}

@media screen {
  @media (min-width: @screen-sm) {
    .basic-policy-modal {
      top: 16px;
    }
  }
  @media (min-width: @screen-md) {
    .basic-policy-modal {
      top: 32px;
    }
  }
}
