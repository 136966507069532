.ant-form-item-control-input-content {
  display: flex;
}

.left-form-item .ant-form-item-label {
  text-align: left;
}

.ant-form-item-label {
  text-align: left;
}

.ant-form-item-label > label {
  white-space: normal;
}

.ant-list-item-meta-content {
  text-align: left;
}
